import React from 'react';
import styled from 'styled-components';

const Info = (props) => {

    const {innerRef} = props;

    return(
        <Root ref={innerRef}>
            <ColumnContainer>
                <AboutImage />
                <AboutMe>
                    <h2>About Me</h2>
                    <Content>
                        After pivoting from my former career as a toxicologist, I am seeking web development opportunities.
                        What originally piqued my interest in React was how similar it was to a hobby of mine: sewing. I liked
                        how the process between making a website was the same as creating a new dress. Planning out the big picture, 
                        breaking the idea down into composable units, and piecing everything together to create a beautiful product. 
                    </Content>
                    <Content>
                        I'm excited about this change in my life: where I get to go from a processor to a creator. I'm eager to begin 
                        creating a greater impact in the work I do and see the results. 
                    </Content>
                </AboutMe>
            </ColumnContainer>
            <DashedLine />
            <ColumnContainer>
                <HobbiesDetail>
                    <h2>Hobbies</h2>
                    <Content>
                        Besides sewing, there are quite a few ways I spend my time. Primarily, with my dogs, Daisy and Leo.
                        You will find us walking across Washington's many trails and mountains. 
                        I also like to play a violin from time to time to de-stress.
                        I am not a great dancer as the picture suggest, but my favorite dance class was traditional Korean dance (I loved the long flowy hanbok).
                    </Content>
                </HobbiesDetail>
                <HobbiesImage/>
            </ColumnContainer>
        </Root>
    )
};

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 900px;
`;

const Root = styled.div`
    display: flex;
    padding-bottom: 5rem;
    padding-top: 2rem;
    z-index: -1;
    width: 80%;
    gap: 1rem;
    margin: 0 auto;
      
    @media (min-width: 1025px) {
        flex-direction: row;
    }
      
    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: row;
    }
      
      
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {    
        flex-direction: row;
    }
      
    @media (max-width: 767px) {
        flex-direction: column;
    } 
`;

const DashedLine = styled.div`
    @media (min-width: 1025px) {
        border-right: 1px dashed #DCDCDC;
        height: 80vh;
    }
      
    @media (min-width: 768px) and (max-width: 1024px) {
        height: 80vh;
        border-right: 1px dashed #DCDCDC;
    }
      
      
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {    
        height: 80vh;
        border-right: 1px dashed #DCDCDC;
    }
      
    @media (max-width: 767px) {
        width: 80vw;
        border-bottom: 1px dashed #DCDCDC;

    } 
`;

const AboutImage = styled.div`
    background-image: url('About.jpg');
    background-repeat: no-repeat;
    aspect-ratio: 16/9;
    max-width: 800px;
    background-size: contain;
    background-position: center;
`;

const AboutMe = styled.div`
    color: black;
    font-family: verdana;
    padding-left: 2rem;
    font-size: 20px;
    letter-spacing: -0.5px
`;

const Content = styled.p`
    font-family: verdana;
    font-size: 16px;
    letter-spacing: -0.5px
    font-weight: normal;
`;

const HobbiesImage = styled.div`
    background-image: url('Hobbies.jpg');
    background-repeat: no-repeat;
    aspect-ratio: 16/9;
    max-width: 800px;
    background-size: contain;
    background-position: center;
`;

const HobbiesDetail = styled.div`
    color: black;
    font-family: verdana;
    padding-left: 2rem;
    font-size: 20px;
    letter-spacing: -0.5px
`;

export default Info;