import React, { createRef } from 'react';
import styled from 'styled-components';
import Header from './components/Layout/Navigation/Header';
import Banner from './components/Layout/Banner';
import Info from './components/Layout/Info';
import Skills from './components/Layout/Skills';
import Portfolio from './components/Layout/Portfolio';

function App() {

  const infoRef = createRef();
  const skillsRef = createRef();
  const portfolioRef = createRef();

  const onClickInfoRef = () => {
    infoRef.current.scrollIntoView();
  }

  const onClickSkillsRef = () => {
    skillsRef.current.scrollIntoView();
  }

  const onClickPortfolioRef = () => {
    portfolioRef.current.scrollIntoView();
  }

  return (
    <AppRoot>
        <Header refActions={{'info': onClickInfoRef, 'skills': onClickSkillsRef, 'portfolio': onClickPortfolioRef}} />
        <Banner />
        <Info innerRef={infoRef}/>
        <Portfolio innerRef={portfolioRef}/>
        <Skills innerRef={skillsRef}/>
    </AppRoot>
  );
}

const AppRoot = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;

export default App;
