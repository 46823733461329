import React, {useState} from 'react';
import styled from 'styled-components'

const Header = (props) => {

    const { refActions } = props;

    const [currentClick, setCurrentClick] = useState(null);
    
    const buttonTexts = [
        {action: refActions.info, "text": "About"},
        {action: refActions.info, "text" : "Hobbies"},
        {action: refActions.portfolio, "text": "Portfolio"},
        {action: refActions.skills, "text": "Skills"}
    ]

    const onHover = (index) => {
        console.log(index)
        setCurrentClick(index);
    };

    const onHoverOff = (_) => {
        setCurrentClick(null);
    }

    const isSelected = (index) => {
        if(currentClick === null) {
            return true;
        }
        if(currentClick === index) {
            return true;
        } else {
            return false;
        }
    }

    //each event requires a function that takes one argument and does "something"
    // (e) => console.log(e)

    const buttons = buttonTexts.map((item, index) => <Buttons 
            key={index} 
            onClick={item.action}
            hovered={isSelected(index)} // resolves to true or false at run time
            onMouseLeave={onHoverOff} 
            onMouseOver={(_) => {onHover(index)}}>{item.text}</Buttons>) // does not resolve to anything... it's a function

    return (
        <React.Fragment>
            <HeaderOne>
                <NameIcon>YOON</NameIcon>
                <Details>
                    {buttons}
                </Details>
            </HeaderOne>
        </React.Fragment>
    )
};

const HeaderOne = styled.div`
    position: fixed;
    width: calc(100% - 2rem);
    height: 25px;
    background-color: #fff;
    box-shadow: 0 1px 0 0 rgb(0, 0, 10%);
    border-bottom: 0.5px solid #DCDCDC;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    margin: 0 auto;

`;

const NameIcon = styled.div`
    width: 50%;
    font-family: verdana;
    font-weight: 700;
    font-size: 16px;
    color: #1e1e1e;
    letter-spacing: -0.5px;
`;

const Details = styled.div`
    display: flex;
    width: 35%;
    justify-content: space-between;
`;

const Buttons = styled.button`
    font-family: verdana;
    font-weight: 700;
    font-size: 16px;
    color: #1e1e1e;
    letter-spacing: -0.5px;
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;

    color: ${props => props.hovered ? '#1e1e1e' : '#DCDCDC'};

`;

export default Header;