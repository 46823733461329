import React from 'react';
import styled from 'styled-components';
import { H1, P } from '../UI/Styled';


const Skills = (props) => {

    const {innerRef} = props;

    const skills = [
        {"img": "react-badge.png", "text": "React"},
        {"img": "Javascript.png", "text": "JS"},
        {"img": "html.png", "text": "HTML"},
        {"img": "css.png", "text": "CSS"}
    ]
    
    return(
        <Root ref={innerRef}>
            <BlackH1>Skills</BlackH1>
            <SkillList>{skills.map((item) => <Skill text={item.text} img={item.img}/>)}</SkillList>
        </Root>
    )
};

const Skill = (props) => {
    const { text, img } = props;
    
    return (
        <SkillRoot>
            <Badge img={img}/>
            <P>{text}</P>
        </SkillRoot>
    )
}

const SkillRoot = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: column;
`;


const BlackH1 = styled(H1)`
    color: black;
`;

const Root = styled.div`
    background-color: white;
    height: 20rem;
    width: 100%;
    display: flex; 
    justify-content: flex-start;
    flex-direction: column;
`;

const Badge = styled.div`
    background-image: url('${props => props.img}');
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
`;

const SkillList = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
`;


export default Skills;