import React from 'react';
import styled from 'styled-components';
import { H1, P } from '../UI/Styled';

const Portfolio = (props) => {

    const {innerRef} = props;

    const links = [
        {"link": "https://zesty-licorice-df1f14.netlify.app", "title": "Doodle", "sourceLink": "https://github.com/yoon33/doodle"},
        {"link": "https://dazzling-palmier-0fd512.netlify.app", "title": "Shopping", "sourceLink": "https://github.com/yoon33/shopping"}
    ]

    return(
        <Root ref={innerRef}>
            <BlackH1>Portfolio</BlackH1>
            <LinksList>{links.map((item) => <Link link={item.link} title={item.title} sourceLink={item.sourceLink}/>)}</LinksList>
        </Root>
    )
};

const Link = (props) => {
    const { link, title, sourceLink} = props;
    
    return (
        <LinkRoot>
            <a href={link}>{title}</a>
            <a href={sourceLink}>Source Code</a>
        </LinkRoot>
    )
}

const LinkRoot = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: column;
`;


const BlackH1 = styled(H1)`
    color: black;
`;

const Root = styled.div`
    background-color: #f4f1ec;
    height: 20rem;
    width: 100%;
    display: flex; 
    justify-content: flex-start;
    flex-direction: column;
`;

const Badge = styled.div`
    background-image: url('${props => props.img}');
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
`;

const LinksList = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
`;


export default Portfolio;