import styled from 'styled-components';

const H3 = styled.h3`
    color: white;
    text-align: center;
`;

const H1 = styled.h1`
    color: white;
    text-align: center;
    font-size: 50px;
`;

const H2 = styled.h2`
    color: white;
    text-align: center;
`;

const P = styled.p`
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
`

export { H1, H2, H3, P };