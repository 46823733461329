import React from 'react';
import styled from 'styled-components';
import { H1, H2, H3 } from '../UI/Styled';

const Banner = () => {
    return(
        <React.Fragment>
            <BannerImage>
                <H3>안녕! I'M YOON</H3>
                <H1>HI! I'M CANDACE</H1>
                <H2>Frontend Developer</H2>
            </BannerImage>
        </React.Fragment>
    )
};

const BannerImage = styled.div`
    background-image: url('BannerPic.JPG');
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-bottom: 30px;
`;

export default Banner;